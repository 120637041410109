<template>
  <v-row class="mt-2">
    <v-col :cols="12" md="8" offset-md="2" lg="6" offset-lg="3">
      <v-card>
        <v-toolbar>
          <v-toolbar-title>GreatScots List Editor</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col v-if="myLists.length > 0" :cols="12" md="6">
              <h5>My Lists</h5>
              <v-list>
                <v-list-item v-for="{ _id, title } in myLists" :key="_id" :to="'/greatscots/edit/' + _id">
                  <v-list-item-title>{{ title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col v-if="sharedLists.length > 0" :cols="12" md="6">
              <h5>Shared Lists</h5>
              <v-list>
                <v-list-item v-for="{ _id, title } in sharedLists" :key="_id" :to="'/greatscots/edit/' + _id">
                  <v-list-item-title>{{ title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-alert v-if="myLists.length === 0 && sharedLists.length === 0" type="info" class="mt-6" outlined>You do not currently have any editable lists. Click the button below to create a list.</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-dialog v-model="newListDialog" width="300">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="success">
                <v-icon left>fal fa-plus-circle</v-icon>
                Add New List
              </v-btn>
            </template>
            <v-card>
              <v-card-title>Add a New List</v-card-title>
              <v-card-text>
                <p>Enter a name for your new list</p>
                <v-text-field v-model="newListTitle" label="New List Title" outlined></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="newListDialog = false">Close</v-btn>
                <v-btn color="success" :disabled="newListTitle === ''" text @click="makeNewList">Add List</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'
export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const myLists = ref([])
    const sharedLists = ref([])

    const service = root.$feathers.service('directory/list')

    onMounted(async () => {
      await service.find({ query: { type: 'list', share: { $elemMatch: { user: user.value.directoryId, role: 'owner' } }, $select: ['title', '_id'] } }).then(({ data }) => {
        myLists.value = data
      })
      await service.find({ query: { type: 'list', share: { $elemMatch: { user: user.value.directoryId, role: { $in: ['admin', 'editor'] } } }, $select: ['title', '_id'] } }).then(({ data }) => {
        sharedLists.value = data
      })
    })

    const newListDialog = ref(false)
    const newListTitle = ref('')

    async function makeNewList () {
      // Check to make sure there is not already an active list for the current user with the same name
      const { data } = await service.find({ query: { owner: user.value.directoryId, title: newListTitle.value } })
      if (data.length > 0) {
        alert('You already have a list with this name. You cannot have multiple lists with you as the owner which have the same name.')
      } else {
        try {
          const { _id } = await service.create({ type: 'list', title: newListTitle.value, groups: [{ group: 'New Group', people: [] }] })
          root.$router.push('/greatscots/edit/' + _id)
        } catch (e) {
          root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error creating list: ' + e })
        }
      }
    }

    return {
      user,
      myLists,
      sharedLists,
      newListDialog,
      newListTitle,
      makeNewList
    }
  }
}
</script>
