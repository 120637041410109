var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":12,"md":"8","offset-md":"2","lg":"6","offset-lg":"3"}},[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("GreatScots List Editor")])],1),_c('v-card-text',[_c('v-row',[(_vm.myLists.length > 0)?_c('v-col',{attrs:{"cols":12,"md":"6"}},[_c('h5',[_vm._v("My Lists")]),_c('v-list',_vm._l((_vm.myLists),function(ref){
var _id = ref._id;
var title = ref.title;
return _c('v-list-item',{key:_id,attrs:{"to":'/greatscots/edit/' + _id}},[_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)}),1)],1):_vm._e(),(_vm.sharedLists.length > 0)?_c('v-col',{attrs:{"cols":12,"md":"6"}},[_c('h5',[_vm._v("Shared Lists")]),_c('v-list',_vm._l((_vm.sharedLists),function(ref){
var _id = ref._id;
var title = ref.title;
return _c('v-list-item',{key:_id,attrs:{"to":'/greatscots/edit/' + _id}},[_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)}),1)],1):_vm._e()],1),(_vm.myLists.length === 0 && _vm.sharedLists.length === 0)?_c('v-alert',{staticClass:"mt-6",attrs:{"type":"info","outlined":""}},[_vm._v("You do not currently have any editable lists. Click the button below to create a list.")]):_vm._e()],1),_c('v-card-actions',[_c('v-dialog',{attrs:{"width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus-circle")]),_vm._v(" Add New List ")],1)]}}]),model:{value:(_vm.newListDialog),callback:function ($$v) {_vm.newListDialog=$$v},expression:"newListDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add a New List")]),_c('v-card-text',[_c('p',[_vm._v("Enter a name for your new list")]),_c('v-text-field',{attrs:{"label":"New List Title","outlined":""},model:{value:(_vm.newListTitle),callback:function ($$v) {_vm.newListTitle=$$v},expression:"newListTitle"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.newListDialog = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"success","disabled":_vm.newListTitle === '',"text":""},on:{"click":_vm.makeNewList}},[_vm._v("Add List")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }